import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Create an async thunk for updating projects
export const updateProject = createAsyncThunk('project/updateProject', async ({ formDataWithImages, projectId }, { rejectWithValue }) => {
      let response;
      try {
        response = await fetch(`/api/projects/${projectId}`, {
          method: 'PUT',
          body: formDataWithImages
      });
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Network response was not ok:', errorData);
        // Reject with error data
        return rejectWithValue(errorData.error || 'Project updating failed');
      }
      const data = await response.json();
      return data;

      } catch (error) {
        console.error('Error in createProject thunk', error);
        return rejectWithValue(error.message || 'An error occurred during the API call');
      }
  });
  

const updateProjectSlice = createSlice({
  name: 'project',
  initialState: { project: null, status: 'idle', error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateProject.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.project = action.payload;
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload || action.error.message;
      });
  },
});

export default updateProjectSlice.reducer;