import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loadGoogleMapsScript } from '../../utils/loadScript';

const apiKey = process.env.REACT_APP_GCLOUD_PLACES_API;

const AddressForm = ({
    addressLine1,
    setAddressLine1,
    addressLine2,
    setAddressLine2,
    city,
    setCity,
    country,
    setCountry,
    postcode,
    setPostcode,
    addressFound,
    setAddressFound
}) => {
    const { t } = useTranslation();

    useEffect(() => {
        // Initialize Google Places Autocomplete after the script is loaded
        const initAutocomplete = () => {
        const input = document.getElementById('autocomplete');
            const autocomplete = new window.google.maps.places.Autocomplete(input, {
                types: ['address'],
                // componentRestrictions: { country: 'es' } // Set country if needed (e.g., Spain)
            });

            // Listen for the event when the user selects an address
            autocomplete.addListener('place_changed', () => {
                const place = autocomplete.getPlace();
                const addressComponents = {};

                addressComponents.addressLine1 = place.name;
                let foundCity = false;
                place.address_components.forEach((component) => {
                    const types = component.types;
                    if (types.includes('postal_town')) {
                        addressComponents.city = component.long_name;
                        foundCity = true; // Mark as found so no other type overwrites it
                    } else if (!foundCity && types.includes('locality')) {
                        addressComponents.city = component.long_name;
                        foundCity = true;
                    } else if (!foundCity && types.includes('administrative_area_level_2')) {
                        addressComponents.city = component.long_name;
                        foundCity = true;
                    } else if (types.includes('country')) {
                        addressComponents.country = component.short_name;
                    } else if (types.includes('postal_code')) {
                        addressComponents.postcode = component.long_name;
                    }
                });

                setAddressLine1(addressComponents.addressLine1 || addressLine1);
                setCity(addressComponents.city || city);
                setCountry(addressComponents.country || country);
                setPostcode(addressComponents.postcode || postcode);
                setAddressFound(input.value || addressFound || '');
              });
        };

        // Load the Google Maps script and initialize Autocomplete
        loadGoogleMapsScript(apiKey, initAutocomplete);

    }, [setAddressLine1, setCity, setCountry, setPostcode, setAddressFound]);

    return (
        <>
        <div className="row gx-3">
            <div className="col-lg-8 mb-4">
                <label className="form-label" htmlFor="autocomplete">{t('registration.addressLine1')}</label>
                <input 
                    className="form-control"
                    id="autocomplete" 
                    type="text" 
                    placeholder={t('registration.addressLine1Placeholder')}
                    value={addressFound || ''}
                    onChange={(e) => setAddressFound(e.target.value)}
                    required 
                />
            </div>
            <div className="col-lg-4 mb-4">
                <label className="form-label" htmlFor="addressLine2">{t('registration.addressLine2')}</label>
                <input
                    className="form-control"
                    type="text"
                    id="addressLine2"
                    name="addressLine2"
                    autoComplete="address-line2"
                    value={addressLine2 || ''}
                    onChange={(e) => setAddressLine2(e.target.value)}
                />
            </div>
        </div>
        </>
    );
};

export default AddressForm;